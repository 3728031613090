import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
export interface DialogData {
  message: string;
}
@Component({
  selector: 'app-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.scss']
})
export class ConfirmationBoxComponent implements OnInit {

  dialogval:any;
  msg:any;
  constructor(public dialogRef: MatDialogRef<ConfirmationBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public diaData: DialogData,
    private router:Router,
    ) { }

  ngOnInit(): void {
    
    this.dialogval=this.diaData
    this.msg=this.dialogval.message
    // this.msg=this.msg.substring(1);
    // this.msg=this.msg.replace(']', ' ');
  }
  pressButton(chose: string) {
    this.dialogRef.close(chose);
  }

  close(){
    // this.router.navigate(['home/receivables/sales/searchsalesorder']);
}

 

}