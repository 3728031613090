import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { updateLabel } from 'typescript';

@Injectable({
  providedIn: 'root'
})
export class PointsaleshippingService {


  constructor(private http: HttpClient) { }
  save(payload): Observable<any[]> {
    return this.http.post<any>(environment.receivablemanagementapi + 'pointsaleshipping', payload);

  }
  update(payload, invNo: any): Observable<any[]> {
    return this.http.put<any>(environment.receivablemanagementapi + 'pointsaleshipping/' + invNo, payload);
  }

  getshipDeatailsByInvNo(invNo: any): Observable<any[]> {
    return this.http.get<any>(environment.receivablemanagementapi + 'pointsaleshipping/'+invNo);
  }
  saveAll(payload){
    return this.http.post<any>(environment.receivablemanagementapi + 'custominvoice/savecustominvoice', payload);
  }
  updateAll(invNo ,payload){
    return this.http.put<any>(environment.receivablemanagementapi + 'custominvoice/updatecustominvoice/' + invNo, payload);
  }
  updateAfterConfirmation(invNo ,payload){
    return this.http.put<any>(environment.receivablemanagementapi + 'custominvoice/updateconfimedinvoice/' + invNo, payload);
  }
  
}
